import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
        meta: {
            keepAlive: true
        },
        children: [
            {
                path: '',
                name: 'surveyQuestion',
                component: () => import('../views/home/surveyQuestion.vue'),
                meta: {
                    title: '首页',
                    icon: 'wap-home',
                    keepAlive: true
                }
            },
            {
                path: 'researchRecords',
                name: 'researchRecords',
                component: () => import('../views/home/researchRecords.vue'),
                meta: {
                    title: '调研记录',
                    icon: 'label',
                    keepAlive: false
                }
            },
            {
                path: 'my',
                name: 'my',
                component: () => import('../views/home/my.vue'),
                meta: {
                    title: '我的',
                    icon: 'manager',
                    keepAlive: false
                }
            }
        ]
    },
    {
        path: '/searchCompany',
        name: 'searchCompany',
        component: () => import('../views/searchCompany.vue'),
        meta: {
            title: '门店查询'
        }
    },
    {
        path: '/editQuestionForm',
        name: 'editQuestionForm',
        component: () => import('../views/editQuestionForm.vue'),
        meta: {
            title: '编辑问卷'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: {
            render() {
                return '404 not font'
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to) => {
    document.title = to.meta.title
})

export default router
