<template>
    <router-view v-slot="{Component, route}">
        <keep-alive>
            <component v-if="route.meta.keepAlive" :is="Component"></component>
        </keep-alive>
        <component v-if="!route.meta.keepAlive" :is="Component"></component>
    </router-view>
</template>

<script setup>
</script>

<style lang="scss">

</style>
