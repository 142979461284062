import CryptoJS from '@/assets/crypto'

export function getScreenPx() {
  let l = Number(window.innerWidth ? window.innerWidth : document.documentElement.clientWidth);
  let styleID = document.getElementById('styleID');
  l = 375
  styleID.innerHTML = 'html {font-size: ' + parseFloat(l / 3.75).toFixed() + 'px;}';
}

export function encode(message, key) {
    return CryptoJS.AES.encrypt(message, CryptoJS.enc.Hex.parse(key), {
        iv: CryptoJS.enc.Latin1.parse('Pkcs7'),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString().replace(/\+/g, '-').replace(/\//g, '.').replace(/=/g, '~')
  }

  export function decode(message, key) {
    return CryptoJS.AES.decrypt(message.replace(/-/g, '+').replace(/\./g, '/').replace(/~/g, '='), CryptoJS.enc.Hex.parse(key), {
        iv: CryptoJS.enc.Latin1.parse('Pkcs7'),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8)
  }