import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './store'
import './assets/css/main.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import { getScreenPx } from './utils'
import './assets/css/vant-variables.scss'

getScreenPx()

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(Vant)
app.mount('#app')
